import React from "react";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { Logo } from "../assets";

export default function LoginLayout() {
  return (
    <>
      <Helmet>
        <title>{"ADMIN Login"}</title>
        <meta name="description" content={"ADMIN Login"} />
        <link rel="icon" href={Logo} />
      </Helmet>
      <Outlet />
    </>
  );
}
