
export const _set_token_in_localStorage = (token) => {
  localStorage.setItem("token", token);
};

export const _get_token_from_localStorage = () => {
  return localStorage.getItem("token");
};

export const _remove_token_from_localStorage = () => {
  localStorage.removeItem("token");
};

export const _set_user_in_localStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const _get_user_from_localStorage = () => {
  return JSON.parse(localStorage.getItem("user"));
};

export const _remove_user_from_localStorage = () => {
  localStorage.removeItem("user");
};  

