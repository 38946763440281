import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  IconButton, CircularProgress, TextField, FormHelperText, styled, Button, MenuItem, Select,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { getCategory } from "../../api/categoryApi";
import { _get_user_from_localStorage } from "../../local_storage/local_storage";
import { updateQuestion, addQuestion } from "../../api/question";
import { useAdminContext } from "../../Hooks/AdminContext";

const HiddenInput = styled("input")({ display: "none" });

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
  audioInput: {
    display: 'none',
  },
  audioPreview: {
    marginTop: '10px',
    width: '100%',
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  optionInput: {
    flexGrow: 1,
    marginRight: '10px',
  },
  uploadButton: {
    minWidth: '120px',
  },
  audioOptionContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
  },
  audioPlayer: {
    width: '50%', // Changed from 100% to 50%
    marginTop: '10px',
    marginBottom: '10px', // Added margin at the bottom
  },
  uploadButton: {
    marginTop: '10px',
  },
}));

const AddOrUpdateQuestion = ({ type }) => {
  const classes = useStyles();
  const { setnavbarTitle } = useAdminContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [file, setProfileImage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [questionType, setQuestionType] = useState('text');
  const [inputs, setInputs] = useState({
    question: "",
    image: "",
    audio: "",
  });

  const [optionType, setOptionType] = useState('text');
  const [options, setOptions] = useState([
    { value: '', file: null },
    { value: '', file: null },
    { value: '', file: null },
    { value: '', file: null },
  ]);

  const [showOptions, setShowOptions] = useState(false);
  const [categoryId, setCategoryId] = useState('');
  const [correctOptionIndex, setCorrectOptionIndex] = useState(null);
  const [categories, setCategories] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
      const response = await getCategory();
        const activeCategories = response.filter(category => category.status==true)
          .map(category => ({ id: category.id, name: category.title }));
        setCategories(activeCategories);
     
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (value && !showOptions) {
      setShowOptions(true);
    }
  };

  const fileChangedHandler = (e) => {
    setProfileImage(URL.createObjectURL(e.target.files[0]));
    setInputs({
      ...inputs,
      ["image"]: e.target.files[0],
    });
  };

  const handleRecordingUpload = (e) => {
    const file = e.target.files[0];
    setInputs((prevInputs) => ({
      ...prevInputs,
      audio: file,
    }));
  };

  const handleQuestionTypeChange = (event) => {
    const newType = event.target.value;
    setQuestionType(newType);
    const newErrors = { ...errors };
    delete newErrors.question;
    delete newErrors.image;
    delete newErrors.audio;
    setErrors(newErrors);
    setInputs(prevInputs => ({
      ...prevInputs,
      question: newType === 'text' ? prevInputs.question : '',
      image: newType === 'image' ? prevInputs.image : '',
      audio: newType === 'audio' ? prevInputs.audio : '',
    }));
  };

  const handleOptionTypeChange = (event) => {
    const newType = event.target.value;
    setOptionType(newType);
    const newErrors = { ...errors };
    options.forEach((_, index) => {
      delete newErrors[`option${index}`];
    });
    setErrors(newErrors);
    setOptions(options.map(() => ({ value: '', file: null })));
  };

  const handleOptionChange = (index, value, file = null) => {
    const newOptions = [...options];
    newOptions[index] = { value, file };
    setOptions(newOptions);
  };

  const handleOptionFileUpload = (index, file) => {
    const newOptions = [...options];
    newOptions[index].file = file;
    setOptions(newOptions);
  };

  const validateForm = () => {
    const newErrors = {};

    // Question validation
    if (questionType === 'text') {
      if (inputs.question.trim().length === 0) {
        newErrors.question = 'Question text is required';
      } else if (inputs.question.length > 500) {
        newErrors.question = 'Question text cannot be more than 500 characters';
      }
    } else if (questionType === 'image') {
      if (!inputs.image) {
        newErrors.image = 'Question image is required';
      } else if (typeof inputs.image === 'string') {
        // Skip validation if it's a string (existing image URL)
      } else if (!['image/jpeg', 'image/png', 'image/webp'].includes(inputs.image.type)) {
        newErrors.image = 'Invalid image format. Please use JPG, PNG, or WEBP';
      } else if (inputs.image.size > 5 * 1024 * 1024) {
        newErrors.image = 'Image size should not exceed 5MB';
      }
    } else if (questionType === 'audio') {
      if (!inputs.audio) {
        newErrors.audio = 'Question audio is required';
      } else if (typeof inputs.audio === 'string') {
        // Skip validation if it's a string (existing audio URL)
      } else if (!['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(inputs.audio.type)) {
        newErrors.audio = 'Invalid audio format. Please use MP3, WAV, or OGG';
      } else if (inputs.audio.size > 10 * 1024 * 1024) {
        newErrors.audio = 'Audio size should not exceed 10MB';
      }
    }

    // Category validation
    if (!categoryId) {
      newErrors.category = 'Category is required';
    }

    // Options validation
    options.forEach((option, index) => {
      if (optionType === 'text') {
        if (option.value.trim().length === 0) {
          newErrors[`option${index}`] = `Option ${index + 1} text is required`;
        } else if (option.value.length > 200) {
          newErrors[`option${index}`] = `Option ${index + 1} text cannot be more than 200 characters`;
        }
      } else if (optionType === 'image') {
        if (!option.value) {
          newErrors[`option${index}`] = `Option ${index + 1} image is required`;
        } else if (typeof option.value === 'string') {
          // Skip validation if it's a string (existing image URL)
        } else if (!['image/jpeg', 'image/png', 'image/webp'].includes(option.value.type)) {
          newErrors[`option${index}`] = `Invalid image format for Option ${index + 1}. Please use JPG, PNG, or WEBP`;
        } else if (option.value.size > 5 * 1024 * 1024) {
          newErrors[`option${index}`] = `Image size for Option ${index + 1} should not exceed 5MB`;
        }
      } else if (optionType === 'audio') {
        if (!option.file) {
          newErrors[`option${index}`] = `Option ${index + 1} audio is required`;
        } else if (typeof option.file === 'string') {
          // Skip validation if it's a string (existing audio URL)
        } else if (!['audio/mpeg', 'audio/wav', 'audio/ogg'].includes(option.file.type)) {
          newErrors[`option${index}`] = `Invalid audio format for Option ${index + 1}. Please use MP3, WAV, or OGG`;
        } else if (option.file.size > 10 * 1024 * 1024) {
          newErrors[`option${index}`] = `Audio size for Option ${index + 1} should not exceed 10MB`;
        }
      }
    });

    // Correct option validation
    if (correctOptionIndex === null) {
      newErrors.correctOption = 'Please select the correct answer';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (!validateForm()) {
      setIsLoading(false);
      return;
    }

    const questionData = {
      categoryId: categoryId,
      type: questionType,
      content: questionType === 'text' ? inputs.question : 
               questionType === 'image' ? inputs.image : 
               inputs.audio,
      optionType: optionType,         
      options: options.map((option, index) => ({
        [optionType]: optionType === 'text' ? option.value : 
                      optionType === 'image' ? option.value : 
                      option.file,
        isCorrect: index === correctOptionIndex
      })),
      createdAt: new Date().toISOString(),
      createdBy: `users/${_get_user_from_localStorage()?.id || null}`,
    }; 

    try {
      const result = type === "edit"
        ? await updateQuestion(questionData, state.id)
        : await addQuestion(questionData);

      enqueueSnackbar('Question added successfully', { variant: "success" });
      navigate(-1);
    } catch (error) {
      console.error("Error submitting question:", error);
      enqueueSnackbar('Error adding question', { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (state && type === "edit") {
      setInputs({
        question: state.questionType === 'text' ? state.content : '',
        image: state.questionType === 'image' ? state.content : '',
        audio: state.questionType === 'audio' ? state.content : '',
      });
      setCategoryId(state.categoryId || "");
      setQuestionType(state.questionType || "text");

      const updatedOptions = state.options.map(option => {
        const key = Object.keys(option).find(k => k !== 'isCorrect');
        return { [key]: option[key] };
      });
      const type = Object.keys(updatedOptions[0])[0];
      setOptionType(type || "text");

      setOptions(state.options.map(option => {
        const key = Object.keys(option).find(k => k !== 'isCorrect');
        if (key === 'audio') {
          return { value: null, file: option[key] };
        }
        return { value: option[key], file: null };
      }) || [
        { value: '', file: null },
        { value: '', file: null },
        { value: '', file: null },
        { value: '', file: null },
      ]);

      const correctOptionIndex = state.options.findIndex(option => option.isCorrect);
      setCorrectOptionIndex(correctOptionIndex || null);
    }
  }, [state, type]);
  useEffect(() => {
    setnavbarTitle("");
  }, []);

  
  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
              >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-2">{`${type == "edit" ? "Edit" : "Add"} Question`}</h2>
        </div>
        
        <div className="pr-4">
        <div className="pt-4 pb-4 p-4 col-lg-12 col-md-12 col-sm-12">
          <h4 className="mb-3">
            <span>
              Choose Question Type
            </span>
          </h4>

          <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
            <label style={{ fontStyle: 'italic' }}>
              <input
                type="radio"
                name="questionType"
                value="text"
                style={{ marginRight: '5px' }}
                onChange={handleQuestionTypeChange}
                checked={questionType === 'text'}
              />
              Text
            </label>
            <label style={{ fontStyle: 'italic' }}>
              <input
                type="radio"
                name="questionType"
                value="audio"
                style={{ marginRight: '5px' }}
                onChange={handleQuestionTypeChange}
                checked={questionType === 'audio'}
              />
              Audio
            </label>
            <label style={{ fontStyle: 'italic' }}>
              <input
                type="radio"
                name="questionType"
                value="image"
                style={{ marginRight: '5px' }}
                onChange={handleQuestionTypeChange}
                checked={questionType === 'image'}
              />
              Image
            </label>
          </div>
        </div>
      </div>


        <form
         onSubmit={handleSubmit}
        >
          <div className="row"> 
            {questionType === 'text' && (
               <div className="col-lg-12 col-md-12 col-sm-12">
               <div className="mt-4 textarea-block">
                 <TextField
                   id="outlined-basic"
                   label="Question"
                   variant="outlined"
                   rows={4}
                   multiline
                   fullWidth
                   size="small"
                   value={inputs.question}
                   name="question"
                   onChange={handleChange}
                   error={!!errors.question}
                   helperText={errors.question}
                 />
                 <FormHelperText>Maximum limit 500 characters</FormHelperText>
               </div>
             </div>
            )}

            {questionType === 'image' && (
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Upload Image *</p>
                    <FormHelperText className="pt-0">
                      Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {inputs.image && (
                      <img src={typeof inputs.image === 'string' ? inputs.image : URL.createObjectURL(inputs.image)} alt="Question" height="50" />
                    )}
                  </div>
                  
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="contained-button-file">
                      <HiddenInput
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="image"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        startIcon={<FileUploadIcon />}
                        component="span"
                        className="capitalized "
                        style={{ border: "2px solid", padding: "10px" }}
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs.image && (
                  <p className="text-secondary">{typeof inputs.image === 'string' ? inputs.image.split('/').pop() : inputs.image.name}</p>
                )}
                {errors.image && <FormHelperText error>{errors.image}</FormHelperText>}
              </div>
            )}

            {questionType === 'audio' && (
              <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Upload Recording *</p>
                    <FormHelperText className="pt-0">
                      Audio formats: MP3, WAV, OGG (Max size: 10MB)
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {inputs.audio && (
                      <audio key={inputs.audio.name || inputs.audio} controls>
                        <source src={typeof inputs.audio === 'string' ? inputs.audio : URL.createObjectURL(inputs.audio)} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    )}
                  </div>
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="recording-upload">
                      <HiddenInput
                        accept="audio/*"
                        id="recording-upload"
                        type="file"
                        name="audio"
                        onChange={handleRecordingUpload}
                      />
                      <Button
                        startIcon={<FileUploadIcon />}
                        component="span"
                        className="capitalized"
                        style={{ border: "2px solid", padding: "10px" }}
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs.audio && (
                  <p className="text-secondary">{typeof inputs.audio === 'string' ? inputs.audio.split('/').pop() : inputs.audio.name}</p>
                )}
                {errors.audio && <FormHelperText error>{errors.audio}</FormHelperText>}
              </div>
            )}

            {/* Replace the category input with a dropdown */}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <Select
                fullWidth
                label="Category"
                value={categoryId}
                onChange={(e) => setCategoryId(e.target.value)}
                className="mt-2"
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Category</em>;
                  }
                  return categories.find(cat => cat.id === selected)?.name || '';
                }}
                error={!!errors.category}
              >
                <MenuItem disabled value="">
                  <em>Select Category</em>
                </MenuItem>
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.category && <FormHelperText error>{errors.category}</FormHelperText>}
            </div>

            {/* New section for option type selection */}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <h4>Choose Options Type</h4>
              <div style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                <label style={{ fontStyle: 'italic' }}>
                  <input
                    type="radio"
                    name="optionType"
                    value="text"
                    style={{ marginRight: '5px' }}
                    onChange={handleOptionTypeChange}
                    checked={optionType === 'text'}
                  />
                  Text
                </label>
                <label style={{ fontStyle: 'italic' }}>
                  <input
                    type="radio"
                    name="optionType"
                    value="image"
                    style={{ marginRight: '5px' }}
                    onChange={handleOptionTypeChange}
                    checked={optionType === 'image'}
                  />
                  Image
                </label>
              </div>
            </div>

            {/* Options section */}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <h4>Add Options</h4>
              <div className="row">
                {options.map((option, index) => (
                  console.log(option.value,'option'),
                  <div key={index} className="col-md-6 mt-3">
                    {optionType === 'text' ? (
                      <TextField
                        fullWidth
                        label={`Option ${index + 1}`}
                        value={option.value}
                        onChange={(e) => handleOptionChange(index, e.target.value)}
                        className="mt-2"
                        error={!!errors[`option${index}`]}
                        helperText={errors[`option${index}`]}
                      />
                    ) :  (
                      <div className="row w-100 div-style ms-0 pt-0">
                        <div className="col-5">
                          <p className="">Upload Image {index + 1} *</p>
                          <FormHelperText className="pt-0">
                            Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                          </FormHelperText>
                        </div>
                        <div className="col-2">
                          {option.value && (
                            <img src={typeof option.value === 'string' ? option.value : URL.createObjectURL(option.value)} alt={`Option ${index + 1}`} height="50" />
                          )}
                        </div>
                        <div className="col-5 text-end pt-2">
                          <label htmlFor={`option-image-${index}`}>
                            <HiddenInput
                              accept="image/*"
                              id={`option-image-${index}`}
                              type="file"
                              onChange={(e) => handleOptionChange(index, e.target.files[0])}
                            />
                            <Button
                              startIcon={<FileUploadIcon />}
                              component="span"
                              className="capitalized"
                              style={{ border: "2px solid", padding: "10px" }}
                            >
                              Upload
                            </Button>
                          </label>
                        </div>
                        {errors[`option${index}`] && <FormHelperText error>{errors[`option${index}`]}</FormHelperText>}
                      </div>
                    ) 
                    }
                  </div>
                ))}
              </div>
            </div>

            {/* New correct answer selection */}
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
              <h4>Select Correct Answer</h4>
              <div className="row">
                {options.map((option, index) => (
                  <div key={index} className="col-md-3 mt-3">
                    <label style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      <input
                        type="radio"
                        name="correctAnswer"
                        value={index}
                        checked={correctOptionIndex === index}
                        onChange={() => setCorrectOptionIndex(index)}
                      />
                      <span>Option {index + 1}</span>
                    </label>
                  </div>
                ))}
              </div>
              {errors.correctOption && <FormHelperText error>{errors.correctOption}</FormHelperText>}
            </div>

              <div className="col-12 text-end mt-5">
                <Button variant="contained" type="submit">
                  {type === "edit" ? "Update" : "Submit"}
                </Button>
              </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrUpdateQuestion;