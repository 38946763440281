import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { DashboardLayout, LoginLayout } from "../layouts";
import CategoriesList from "../pages/Categories/CategoriesList";
import QuestionList from "../pages/Questions/QuestionList";
import AddOrUpdateCategories from "../pages/Categories/AddOrUpdateCategories";
import AddOrUpdateQuestion from "../pages/Questions/AddOrUpdateQuestion";

import Login from "../pages/Login/Login";
import { Dashboard } from "../pages";

const ProtectedRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  return children;
};

// New LoginRoute component
const LoginRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  if (token) {
    return <Navigate to="/categories" replace />;
  }
  return children;
};

const Authentication = () => {
  return <Navigate to="/categories" />;
};

export default function Routers() {
  return (
    <Routes>
      <Route element={<LoginRoute><LoginLayout /></LoginRoute>}>
        <Route path="/login" element={<Login />} />
      </Route>

      <Route element={<ProtectedRoute><DashboardLayout /></ProtectedRoute>}>
        <Route path="/" element={<Authentication />} />
        <Route path="/categories" element={<CategoriesList />} />
        <Route path="/questions" element={<QuestionList />} />
        <Route path="/categories/add-category" element={<AddOrUpdateCategories />} />
        <Route path="/questions/add-question" element={<AddOrUpdateQuestion />} />
        <Route
          path="/categories/add-category/:id"
          element={<AddOrUpdateCategories type={"edit"} />}
        />
        <Route
          path="/questions/add-question/:id"
          element={<AddOrUpdateQuestion type={"edit"} />}
        />
      </Route>

      <Route path="*" element={<Navigate to="/login" replace />} />
    </Routes>
  );
}
