import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  IconButton,
  CircularProgress,
  TextField,
  FormHelperText,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import { useAdminContext } from "../../Hooks/AdminContext";
import { addCategory, updateCategory } from "../../api/categoryApi";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const HiddenInput = styled('input')({
  display: 'none',
});

const AddOrUpdateCategories = ({ type }) => {
  const classes = useStyles();
  const { setnavbarTitle } = useAdminContext();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [inputs, setInputs] = useState({
    title: "",
    status: true,
    description: "",
    image: null,
  });
  const [errors, setErrors] = useState({
    title: '',
    description: '',
    image: '',
  });

  const handleChange = (e) => {
    let name = e.target.name;
    let newValue = e.target.value;
    setInputs((prevState) => ({
      ...prevState,
      [name]: newValue,
    }));
    setErrors(prev => ({ ...prev, [name]: '' }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = { title: '', description: '', image: '' };
    let hasError = false;

    if (!inputs.title.trim()) {
      newErrors.title = 'Title is required';
      hasError = true;
    } else if (inputs.title.length > 100) {
      newErrors.title = 'Title cannot be more than 100 characters';
      hasError = true;
    }

    if (inputs.description.length > 500) {
      newErrors.description = 'Description cannot be more than 500 characters';
      hasError = true;
    }

    if (!inputs.image) {
      newErrors.image = 'Category image is required';
      hasError = true;
    } else if (typeof inputs.image === 'string') {
      // Skip validation if it's a string (existing image URL)
    } else if (!['image/jpeg', 'image/png', 'image/webp'].includes(inputs.image.type)) {
      newErrors.image = 'Invalid image format. Please use JPG, PNG, or WEBP';
      hasError = true;
    } else if (inputs.image.size > 5 * 1024 * 1024) {
      newErrors.image = 'Image size should not exceed 5MB';
      hasError = true;
    }

    setErrors(newErrors);
    if (hasError) return;

    setIsLoading(true);

    try {
      const categoryData = {
        title: inputs.title.trim(),
        image: inputs.image,
        status: inputs.status,
        pin:false,
        description: inputs.description.trim(),
        id: type === "edit" ? state._id : crypto.randomUUID().slice(0, 4),
        createdAt: new Date().toISOString(),
        createdBy: "Admin",
      };
      let result;
      if (type === "edit") {
        result = await updateCategory(state._id, categoryData);
      } else {
        result = await addCategory(categoryData);
      }

      enqueueSnackbar("Category successfully " + (type === "edit" ? "updated" : "added"), { variant: "success" });
      navigate(-1);
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar(error.message || "An error occurred while processing the category", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const fileChangedHandler = (e) => {
    if (e.target.files[0]) {
      setInputs(prevInputs => ({
        ...prevInputs,
        image: e.target.files[0],
      }));
      setErrors(prevErrors => ({ ...prevErrors, image: '' }));
    }
  };

  useEffect(() => {
    if (state) {
      setInputs(state);
    }
  }, [state]);
  useEffect(() => {
    setnavbarTitle("");
  }, []);

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  return (
    <div className="container">
      <div className="row mobile-margin display-flex">
        <div className="col-12 d-flex ">
          <span>
            <IconButton
              className="back-screen-button mb-4"
              onClick={() => navigate(-1)}
            >
              <ArrowBackIcon />
            </IconButton>
          </span>

          <h2 className="ms-2">{`${type == "edit" ? "Edit" : "Add"} Category`}</h2>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                fullWidth
                name="title"
                value={inputs.title}
                onChange={handleChange}
                error={!!errors.title}
                helperText={errors.title}
              />
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 mt-4">
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  value={inputs.status}
                  label="Status *"
                  onChange={handleChange}
                >
                  <MenuItem value={true}>Active</MenuItem>
                  <MenuItem value={false}>Inactive</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                <div className="row w-100 div-style ms-0 pt-0">
                  <div className="col-5">
                    <p className="">Upload Image *</p>
                    <FormHelperText className="pt-0">
                      Image Size(1000 X 670) ("JPG", "JPEG", "PNG","WEBP")
                    </FormHelperText>
                  </div>
                  <div className="col-2">
                    {inputs.image && (
                      <img src={typeof inputs.image === 'string' ? inputs.image : URL.createObjectURL(inputs.image)} alt="Question" height="50" />
                    )}
                  </div>
                  
                  <div className="col-5 text-end pt-2">
                    <label htmlFor="contained-button-file">
                      <HiddenInput
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        name="image"
                        onChange={fileChangedHandler}
                      />

                      <Button
                        startIcon={<FileUploadIcon />}
                        component="span"
                        className="capitalized "
                        style={{ border: "2px solid", padding: "10px" }}
                      >
                        Upload
                      </Button>
                    </label>
                  </div>
                </div>
                {inputs.image && (
                  <p className="text-secondary">{typeof inputs.image === 'string' ? inputs.image.split('/').pop() : inputs.image.name}</p>
                )}
                {errors.image && <FormHelperText error>{errors.image}</FormHelperText>}
              </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="mt-4 textarea-block">
                <TextField
                  id="outlined-basic"
                  label="Description"
                  variant="outlined"
                  rows={4}
                  multiline
                  fullWidth
                  size="small"
                  value={inputs.description}
                  name="description"
                  onChange={handleChange}
                  error={!!errors.description}
                  helperText={errors.description}
                />
                <FormHelperText>Maximum limit 500 characters</FormHelperText>
              </div>
            </div>
            <div className="text-end ms-auto">
              <Button variant="contained" type="submit">
                {type == "edit" ? "Update" : "Submit"}
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddOrUpdateCategories;
