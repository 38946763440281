import React, { useState } from "react";
import Visibility from "@mui/icons-material/VisibilityOutlined";
import VisibilityOff from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";
import { _admin_login_api } from "../../../api/auth";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { _set_token_in_localStorage, _set_user_in_localStorage } from "../../../local_storage/local_storage";
import { useSnackbar } from "notistack";
const LoginForm = ({ setFormState }) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState({
    email: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleSubmitLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    let postData = {
      email: inputs.email,
      password: inputs.password,
      type: 0,
    };
    try {
      const user = await _admin_login_api(postData);
      if (user) {
        _set_token_in_localStorage(user.accessToken);
        _set_user_in_localStorage({
          email: user.email,
          id: user.uid,
          name: user.name,
          role: user.role,
          image: user.image,
          createdAt: user.createdAt,
        });
        enqueueSnackbar("Login successful", { variant: "success" });
        navigate(`/categories`, { replace: true });
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (error) {
      console.error("Login error:", error);
      enqueueSnackbar(error.message || "Login failed", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {" "}
      <div className="col-12 text-center ">
        <h3 className="login-text">Login</h3>
      </div>
      <form onSubmit={handleSubmitLogin}>
        <div className="col-12 mt-4">
          <TextField
            id="outlined-basic"
            label="E-mail"
            name="email"
            variant="outlined"
            fullWidth
            required
            placeholder="E-mail"
            value={inputs.email}
            onChange={handleChange}
          />
        </div>
        <div className="col-12 mt-3 mb-1">
          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-password">
              Password *
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={showPassword ? "text" : "password"}
              placeholder="Password *"
              fullWidth
              required
              name="password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <Visibility
                      style={{
                        fontSize: "18px",
                      }}
                    />
                     
                    ) : (
                      <VisibilityOff
                      style={{
                        fontSize: "18px",
                      }}
                    />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
              value={inputs.password}
              onChange={handleChange}
            />
          </FormControl>
        </div>

        <div className="col-12 mt-2">
          <div className="position-relative">
            <div
              className="float-end pb-3 pointer"
              onClick={() => {
                setFormState(0);
              }}
            >
              <a className="text-muted">Forgot Password</a>
            </div>
          </div>
        </div>

        <div className="mt-3">
          <Button
            type="submit"
            variant="contained"
            className="w-100 login-page-button"
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Login"}
          </Button>
        </div>
      </form>
    </>
  );
};

export default LoginForm;
