import { getDatabase, ref, get, push,update,remove } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
export const getQuestion = async () => {
  try {
    const db = getDatabase(); 
    const questionsRef = ref(db, 'questions'); 
    const snapshot = await get(questionsRef);
    if (snapshot.exists()) {
      return Object.entries(snapshot.val()).map(([id, questionData]) => ({
        id,
        ...questionData
      }));
    } else {
      console.log("No questions found in Realtime Database.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching questions:", error);
    return null;
  }
};

export const uploadFileToStorage = async (file, folder) => {
  const storage = getStorage();
  const fileRef = storageRef(storage, `${folder}/${file.name}`);
  const metadata = {
    contentType: file.type,
  };
  try {
      await uploadBytes(fileRef, file,metadata);
      const downloadURL = await getDownloadURL(fileRef);
      console.log(downloadURL);
      return downloadURL;
  } catch (error) {
      console.error("Error uploading file:", error);
      throw error; 
  }
};


export const addQuestion = async (questionData) => {
  const db = getDatabase();
  const questionsRef = ref(db, 'questions');

  try {
    if (questionData.type === 'image' || questionData.type === 'audio') {
      if (typeof questionData.content !== 'string') {
        questionData.content = await uploadFileToStorage(questionData.content, `questions/${questionData.type}s`);
      }
    }
    if (questionData.options) {
      questionData.options = await Promise.all(
        questionData.options.map(async (option) => {
          const firstKey = Object.keys(option)[0];
          if (firstKey !== 'text' && typeof option[firstKey] !== 'string') {
            const fileURL = await uploadFileToStorage(option[firstKey], `options/${firstKey}s`);
            return { [firstKey]: fileURL, isCorrect: option.isCorrect };
          }
          return option;
        })
      );
    }

    const newQuestionRef = push(questionsRef, questionData);
    return newQuestionRef.key;
  } catch (error) {
    console.error("Error adding question:", error);
    throw error;
  }
};

export const updateQuestion = async (questionData, questionId) => {
  const db = getDatabase();
  const questionRef = ref(db, `questions/${questionId}`);

  try {
    if (questionData.type === 'image' || questionData.type === 'audio') {
      if (typeof questionData.content !== 'string') {
        questionData.content = await uploadFileToStorage(questionData.content, `questions/${questionData.type}s`);
      }
    }

    if (questionData.options) {
      questionData.options = await Promise.all(
        questionData.options.map(async (option) => {
          const firstKey = Object.keys(option)[0];
          if (firstKey !== 'text' && typeof option[firstKey] !== 'string') {
            const fileURL = await uploadFileToStorage(option[firstKey], `options/${firstKey}s`);
            return { [firstKey]: fileURL, isCorrect: option.isCorrect };
          }
          return option;
        })
      );
    }

    await update(questionRef, questionData);
    return { code: 200, message: "Question updated successfully" };
  } catch (error) {
    console.error("Error updating question:", error);
    return { code: 500, message: "Failed to update question" };
  }
};
export const deleteQuestion = async (questionId) => {
  try {
    const db = getDatabase(); 
    const questionRef = ref(db, `questions/${questionId}`); 
    await remove(questionRef);
    return { code: 200, message: "Question deleted successfully" };
  } catch (error) {
    console.error("Error deleting question:", error);
    return { code: 500, message: "Failed to delete question" };
  }
};
