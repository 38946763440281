import { Button, CircularProgress, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSnackbar } from "notistack";
import CustomTable from "../../components/customTable/CustomTable";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import { updateCategoryPin } from "../../api/categoryApi";
import Iconify from "../../components/Iconify";
import { useAdminContext } from "../../Hooks/AdminContext";

import { getCategory, deleteCategory } from "../../api/categoryApi";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const CategoriesList = () => {
  const navigate = useNavigate();
  const { setnavbarTitle } = useAdminContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [categories, setCategories] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [deleteDoc, setDeleteDoc] = useState("");
  const [openDelete, setOpenDelete] = useState(false);

  const handleEdit = (value) => {
    navigate(`/categories/add-category/${value?._id}`, {
      state: {...value,title:value.name,description:value.description.content,image:value.content.content},
    });
  };

  const handleAgreeDelete = (value) => {
    setOpenDelete(true);
    setDeleteDoc(value);
  };

  const handlePin = async (value) => {

    const newPinStatus = !value.pin;
    const result = await updateCategoryPin(value._id,  newPinStatus);
    enqueueSnackbar(`Category ${newPinStatus ? 'pinned' : 'unpinned'} successfully`, { variant: "success" });
    fetchCategories();
  };

  const MENU_OPTIONS = [
    {
      label: "Edit",
      icon: "akar-icons:edit",
      handleClick: handleEdit,
    },

    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleAgreeDelete,
    },
    {
      label: "Pin",
      icon: "ic:round-push-pin",
      handleClick: handlePin,
    },
  ];
  const handleDelete = async () => {
    setOpenDelete(false);

      const result = await deleteCategory(deleteDoc._id);
        setCategories((prevCategories) => {
          return prevCategories.filter((category) => category._id !== deleteDoc._id);
        });
        enqueueSnackbar('Deleted successfully', { variant: "success" });
        
  };
  const handleNavigate = () => {
    navigate("/categories/add-category");
  };

  const TABLE_HEAD = [
    { id: "_id", label: "ID"},
    { id: "name", label: "Name" },
    { id: "content", label: "Image" },
    { id: "description", label: "Description" },
    { id: "createdAt", label: "Created at" },
    { id: "status", label: "Status" ,type:"row_status"},
    { id: "pin", label: "Pin", type: "pin"},
    { id: "action", label: "Action", type: "action" },
  ];

  useEffect(() => {
    setnavbarTitle("Categories");
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      setIsLoading(true);
      const response = await getCategory();
      console.log(response,'response');
      const formattedCategories = response.map((category) => ({
        _id: category.id,
        name: category.title,
        description:{
          content:category.description,
        },
        content:{
          content:category.image,
          type:"image",
        },
        pin:category.pin,
        status: category.status,
        createdAt: new Date(category.createdAt).toLocaleDateString('en-US', {
          day: '2-digit',
          month: 'short',
          year: '2-digit'
        }),
      }));

      setCategories(formattedCategories);
    } catch (error) {
      console.error("Error fetching categories:", error);
      enqueueSnackbar("Failed to fetch categories", { variant: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }
  
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-8"></div>
          <div className="col-4 mt-2  ps-0" style={{ textAlign: "end" }}>
            <div className="d-flex   justify-content-end mb-3 add-button">
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={handleNavigate}
                className="capitalized"
              >
                Add Category
              </Button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 mt-1 ">
            <CustomTable
              TABLE_HEAD={TABLE_HEAD}
              data={categories}
              MENU_OPTIONS={MENU_OPTIONS}
              className="card-with-background"
            />
          </div>
        </div>
        <DeleteConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          title={"Are you sure you want to delete?"}
          handleAgree={handleDelete}
        />
      </div>
    </>
  );
};

export default CategoriesList;
