import React from "react";
import Iconify from "../../components/Iconify";

const getIcon = (name) => <Iconify icon={name} width={20} height={20} />;

export const SideBarConfig = () => {
  let sidebarMenus = [];

  sidebarMenus.push({
    title: "Categories",
    path: "/categories",
    icon: getIcon("icomoon-free:user-tie"),
  });

  sidebarMenus.push({
    title: "Questions",
    path: "/questions",
    icon: getIcon("icomoon-free:user-tie"),
  });
  
  return sidebarMenus;
};
