import { useRef, useState, useEffect } from "react";
import { Box, Avatar, Button } from "@mui/material";
import { _get_user_from_localStorage, _remove_user_from_localStorage, _remove_token_from_localStorage } from "../../local_storage/local_storage";
import { adminIcon } from "../../assets";
import ProfilePopover from "../../components/ProfilePopover";
import Iconify from "../../components/Iconify";
import { useNavigate } from "react-router-dom";
// ----------------------------------------------------------------------

export default function AccountPopover({ setIsLoading }) {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [adminInfo, setAdminInfo] = useState({
    name: "",
    email: "",
    role: "",
    image: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    const storedAdminInfo = _get_user_from_localStorage();
    if (storedAdminInfo) {
      setAdminInfo(storedAdminInfo);
    }
  }, []);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    _remove_token_from_localStorage();  
    _remove_user_from_localStorage();
    setIsLoading(true);
    navigate("/login");
  };

  return (
    <div className="mt-4 px-0">
      <div className="d-flex justify-content-between">
        <div className="left-item">
        </div>
        <div className="right-item ms-3">
          <div className="profile-container" onClick={handleOpen}>
            <div className="profile-avatar">
              <Avatar
                variant="rounded"
                sx={{ width: 40, height: 40 }}
                src={adminInfo.image}
              />
            </div>
            <div className="profile-info">
              <span className="user-name">{adminInfo.name}</span>
              <span className="user-role">{adminInfo.role}</span>
            </div>

            <span className="chevron-icon pointer">
              <i
                className={`ms-3 me-2 fa-solid ${
                  open ? "fa-chevron-up" : "fa-chevron-down"
                } `}
              ></i>
            </span>
          </div>
        </div>
      </div>

      <ProfilePopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220, borderRadius: 5, border: "1px solid #cecece" }}
      >
        {
          <Box sx={{ my: 1.5, px: 2.5 }} className="profile-container-view">
            <Avatar
              variant="rounded"
              sx={{
                width: 70,
                height: 70,
                mx: "auto",
                borderRadius: 3,
              }}
              src={adminInfo.image}
            />
            <div className="profile-info-view">
              <span className="user-name-view">{adminInfo.name}</span>
              <span className="user-email-view">{adminInfo.email}</span>
              <span className="user-role-view">{adminInfo.role}</span>
            </div>
            <Button
              fullWidth
              color="inherit"
              variant="text"
              onClick={handleLogout}
              sx={{ mt: 2 }}
            >
              Logout
            </Button>
          </Box>
        }
      </ProfilePopover>
    </div>
  );
}
