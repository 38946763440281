import { ref, get } from "firebase/database"; 
import { auth, database } from '../firebase/config';
import { signInWithEmailAndPassword } from "firebase/auth";

const _admin_login_api = async (formData) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, formData.email, formData.password);
    const user = userCredential.user;
    const userRef = ref(database, 'users/' + user.uid);
    const snapshot = await get(userRef);
    
    if (snapshot.exists()) {
      const userDetails = snapshot.val();
      const combinedUserData = {
        uid: user.uid,
        email: user.email,
        ...userDetails
      };
      
      return combinedUserData;
    } else {
      return { uid: user.uid, email: user.email };
    }
  } catch (error) {
    if (error.code) {
      console.error("Error code:", error.code);
      if (error.code === 'auth/user-not-found') {
        console.error("No user found with this email.");
      } else if (error.code === 'auth/wrong-password') {
        console.error("Incorrect password.");
      }
    }
    console.error("Error logging in:", error.message);
    throw error; 
  }
};

export { _admin_login_api };

