import { ref, get, set, remove } from "firebase/database"; 
import { database } from '../firebase/config';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";


const COLLECTION_NAME = 'categories';

/**
 * Get all categories from Realtime Database.
 * @returns {Promise<Array>} Array of categories
 */

export const getCategory = async () => {
  try {
    const categoriesRef = ref(database, COLLECTION_NAME);
    const snapshot = await get(categoriesRef);

    if (snapshot.exists()) {
      const categories = Object.entries(snapshot.val()).map(([id, data]) => ({ id, ...data }));
      
      // Sort categories: pinned first, then unpinned
      return categories.sort((a, b) => {
        if (a.pin === b.pin) return 0; // If both are pinned or unpinned, keep original order
        return a.pin ? -1 : 1; // Pinned categories come first
      });
    } else {
      console.log("No categories found in Realtime Database.");
      return [];
    }
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

/**
 * Add a new category to Realtime Database.
 * @param {Object} category - The category to add
 * @returns {Promise<Object>} The added category with its ID
 */

export const uploadFileToStorage = async (file, folder) => {
  const storage = getStorage();
  const fileRef = storageRef(storage, `${folder}/${file.name}`);
  const metadata = {
    contentType: file.type,
  };
  try {
      await uploadBytes(fileRef, file,metadata);
      const downloadURL = await getDownloadURL(fileRef);
      console.log(downloadURL);
      return downloadURL;
  } catch (error) {
      console.error("Error uploading file:", error);
      throw error; 
  }
};

export const addCategory = async (category) => {
  
  try {
    let image = null;
    console.log(category.image);
    
    if (typeof category.image !== 'string') {
      image = await uploadFileToStorage(category.image, 'categories');
    }

    const categoryData = {
      ...category,
      image: image 
    };

    const newCategoryRef = ref(database, `${COLLECTION_NAME}/${category.id}`);
    await set(newCategoryRef, categoryData);
    return categoryData;
  } catch (error) {
    console.error("Error adding category:", error);
    throw error;
  }
};

/**
 * Update an existing category in Realtime Database.
 * @param {Object} category - The category to update, must include id
 * @returns {Promise<Object>} The updated category
 */

export const updateCategory = async (categoryId, category) => {
  try {
    let image = category.image;
    
    // Only update the image if a new file is provided
    if (category.image && typeof category.image === 'object') {
      image = await uploadFileToStorage(category.image, 'categories');
    }

    const categoryData = {

      ...category,
      image: image || null // Ensure imageUrl is never undefined
    };


    const categoryRef = ref(database, `${COLLECTION_NAME}/${categoryId}`);
    await set(categoryRef, categoryData);
    return categoryData;
  } catch (error) {
    console.error("Error updating category:", error);
    throw error;
  }
};

/**
 * Delete a category from Realtime Database.
 * @param {string} categoryId - The ID of the category to delete
 * @returns {Promise<Object>} The deleted category ID
 */
export const deleteCategory = async (categoryId) => {
  try {
    const categoryRef = ref(database, `${COLLECTION_NAME}/${categoryId}`);
    await remove(categoryRef);
    return { id: categoryId };
  } catch (error) { 
    console.error("Error deleting category:", error);
    throw error;
  }
};

export const getCategoryById = async (categoryId) => {
  try {
    const categoryRef = ref(database, `questions/${categoryId}`);
    const snapshot = await get(categoryRef);
    return snapshot.val();
  } catch (error) {
    console.error("Error fetching category by ID:", error);
    throw error;
  }
};  

export const updateCategoryPin = async (categoryId, pinStatus) => {
  try {
    const categoryRef = ref(database, `${COLLECTION_NAME}/${categoryId}`);
    
    // Get the current category data
    const snapshot = await get(categoryRef);
    const currentCategory = snapshot.val();

    // Update only the pin field
    const updatedCategory = {
      ...currentCategory,
      pin: pinStatus
    };
    
    // Set the updated category data
    await set(categoryRef, updatedCategory);
    return updatedCategory;
  } catch (error) {
    console.error("Error updating category pin:", error);
    throw error;
  }
}
